<template>
  <div class="page-container procedures-page">
    <portal to="page-name">Serviços, Taxas e Produtos</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Serviços, Taxas e Produtos</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-12 form-group">
            <label class="form-label">Módulo</label>
            <select class="form-select" v-model="module" @change="setTypes">
              <option value="">[Todos]</option>
              <option v-for="(item, i) in modules" :key="i"
                      :value="item.key">{{ item.name }}</option>
            </select>
          </div>
          <div class="column col-12 form-group">
            <label class="form-label">Tipos</label>
            <div class="types">
              <label class="form-checkbox"
                     v-for="(item, i) in types" :key="i">
                <input type="checkbox" v-model="item.selected" @change="changedTypes">
                <i class="form-icon"/>{{ item.name }}
              </label>
            </div>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 form-group">
            <label class="form-label">Modalidade</label>
            <select class="form-select" v-model="filter.modality"
                    :disabled="!hasExam">
              <option value="">[Todas as modalidades]</option>
              <option v-for="(text, value, i) in modalities" :key="i"
                      :value="value">{{ value }} - {{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label class="form-label">Pesquisar</label>
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" autocomplete="nope"
                     placeholder="Informe o nome ou código para pesquisar...">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto mb-2"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/>Novo registro
            </button>
            <button class="btn btn-gray btn-icon btn-icon-left"
                    @click="openModalPrint">
              <fa-icon :icon="['fal', 'print']"/>Imprimir
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Serviços, Taxas e Produtos</p>
            <p class="empty-subtitle">
              Nenhum registro encontrado. Verifique os filtros para realizar novas buscas<br>
              <small>(Consultas, procedimentos, exames, produtos e etc...)</small>
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" style="width: 40px">#</th>
              <th>Nome</th>
              <th>TUSS</th>
              <th>Tipo</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>
                <router-link :to="editLink(item)">{{ item.name }}</router-link>
                <small
                  v-if="item.specialty?.name"
                  class="ml-2 label label-gray"
                >{{ item.specialty.name }}</small>
              </td>
              <td class="hide-sm">
                {{ item.tuss ? item.tuss.code : '' | tuss }}
              </td>
              <td class="hide-sm">{{ procedureType.getName(item.type) }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                        @click="edit(item)" data-tooltip="Editar">
                  <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
    <dx-modal title="Relação de serviços, taxas e produtos"
              :value="printModal.show" size="sm"
              id="modal-expense-print" @input="printModal.show = false">
      <div class="columns">
        <div class="column col-12 form-group"
             :class="{'has-error': $v.printModal.insurancePlanId.$error}">
          <label class="form-label">Convênio</label>
          <select id="filter-insurance" class="form-select"
                  v-model="printModal.insurancePlanId">
            <option value="">[Selecione]</option>
            <option value="ALL">Todos os convênios</option>
            <option v-for="(insurance, i) in printModal.insurances"
                    :value="insurance.plan.id" :key="i">{{ insurance.customName }}</option>
          </select>
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Especialidade</label>
          <select id="filter-insurance" class="form-select"
                  v-model="printModal.specialtyCode">
            <option value="">[Todas as especialidades]</option>
            <option v-for="(item, i) in printModal.specialties"
                    :value="item.code" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Imprimir valores</label>
          <select id="filter-value" class="form-select"
                  v-model="printModal.showValue">
            <option value="false">Não</option>
            <option value="true">Sim</option>
          </select>
        </div>
      </div>
      <div class="expense-list">
        <div class="expense-scroll-list-wrapper">
          <div class="scroll-list">
            <div class="list-header">
              <label class="form-checkbox">
                <input type="checkbox"
                       @change="selectAllTypes"
                       :checked="getTypesSelected"
                       :indeterminate.prop="getTypesSelected === null">
                <i class="form-icon"></i>
              </label>
              <div class="list-title">Tipos</div>
            </div>
            <table class="table table-hover">
              <tbody>
              <tr v-for="(item, i) in printModal.types" :key="i">
                <td style="width:30px">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="item.selected">
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td>{{ item.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                :class="{loading: printModal.printing}"
                :disabled="printModal.printing"
                @click="print">
          <fa-icon :icon="['fal', 'print']"/>Imprimir
        </button>
        <button class="btn" @click="printModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import * as procedureType from 'src/data/expense-types';
import { modalities } from '@/data/expense-modalities';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      procedureType,
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      modalities,
      types: [],
      modules: [
        { key: 'procedure', name: 'Procedimentos' },
        { key: 'service', name: 'Serviços e Taxas' },
        { key: 'product', name: 'Produtos' },
      ],
      module: '',
      filter: {
        types: [],
        modality: '',
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      printModal: {
        show: false,
        printing: false,
        showValue: true,
        insurances: [],
        specialties: [],
        insurancePlanId: '',
        specialtyCode: '',
        types: [],
        selectedTypes: ['procedure', 'consultation'],
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 800);
      },
      deep: true,
    },
  },
  mounted() {
    this.types = this.getTypes();
    this.load();
  },
  validations() {
    return {
      printModal: {
        insurancePlanId: { required },
      },
    };
  },
  computed: {
    getTypesSelected() {
      if (!this.printModal.types || this.printModal.types.length === 0) {
        return false;
      }

      const selected = this.printModal.types.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.printModal.types.length) {
        return true;
      }
      return null;
    },
    hasExam() {
      return this.types.some(item => item.selected && item.key === 'exam');
    },
  },
  methods: {
    setTypes() {
      let filters = [];
      if (this.module === 'procedure') {
        filters = ['evaluation', 'session', 'consultation', 'exam', 'procedure', 'laboratory'];
      } else if (this.module === 'service') {
        filters = ['tax', 'surgery', 'daily', 'gas'];
      } else if (this.module === 'product') {
        filters = ['medicine', 'material', 'others'];
      }

      this.types.forEach((item) => {
        item.selected = filters.includes(item.key) || this.module === '';
        return item;
      });

      this.changedTypes();
    },
    selectAllTypes(e) {
      this.printModal.types.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    getTypes() {
      const types = [];
      Object.keys(this.procedureType.types).forEach((item) => {
        types.push({
          key: item,
          name: this.procedureType.types[item],
          selected: true,
        });
      });
      return types;
    },
    changedTypes() {
      this.filter.types = this.types.filter(({ selected }) => selected).map(({ key }) => key);
    },
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.types) {
        params.types = this.types
          .filter(({ selected }) => selected)
          .map(({ key }) => key).join(',');
      }

      if (this.filter.modality && this.hasExam) {
        params.modality = this.filter.modality;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$httpX.get('/procedures', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    loadInsurances() {
      const params = {
        active: true,
        limit: 0,
      };
      this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.printModal.insurances = data.items;
        })
        .catch(() => {});
    },
    loadSpecialties() {
      const params = {
        limit: 0,
        isSpecialty: true,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => {
          this.printModal.specialties = data.items;
        })
        .catch(() => {
          this.$toast.error('Erro ao carregar as especialidades');
        });
    },
    setPrintTypes() {
      let filters = [];
      if (this.module === 'procedure') {
        filters = ['evaluation', 'session', 'consultation', 'exam', 'procedure', 'laboratory'];
      } else if (this.module === 'service') {
        filters = ['tax', 'surgery', 'daily', 'gas'];
      } else if (this.module === 'product') {
        filters = ['medicine', 'material', 'others'];
      }

      this.printModal.types.forEach((item) => {
        item.selected = filters.includes(item.key) || this.module === '';
        return item;
      });

      this.changedTypes();
    },
    openModalPrint() {
      this.$v.printModal.insurancePlanId.$reset();
      this.printModal.types = this.getTypes();
      this.setPrintTypes();
      if (this.printModal.specialties.length === 0) {
        this.loadSpecialties();
      }
      if (this.printModal.insurances.length === 0) {
        this.loadInsurances();
      }
      this.printModal.insurancePlanId = '';
      this.printModal.show = true;
    },
    create() {
      this.$router.push('/clinical/procedures/create');
    },
    edit(item) {
      this.$router.push(`/clinical/procedures/${item.id}/edit`);
    },
    editLink(item) {
      return `/clinical/procedures/${item.id}/edit`;
    },
    print() {
      this.$v.printModal.$touch();
      if (this.$v.printModal.$error) {
        return null;
      }

      this.printModal.printing = true;

      const params = {
        showValue: this.printModal.showValue,
      };

      if (this.printModal.insurancePlanId && this.printModal.insurancePlanId !== 'ALL') {
        params.insurancePlanId = this.printModal.insurancePlanId;
      }

      if (this.printModal.specialtyCode) {
        params.specialtyCode = this.printModal.specialtyCode;
      }

      if (!this.getTypesSelected) {
        const types = this.printModal.types
          .filter(({ selected }) => selected)
          .map(({ key }) => key).join(',');

        if (types.length > 0) {
          params.types = types;
        }
      }

      return this.$file
        .print('/procedures/print', params)
        .catch(() => {})
        .then(() => {
          this.printModal.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.procedures-page {
  .btn-fixed-width {
    font-size: $font-size-lg;
    height: 2.2rem;
    margin: $layout-spacing-lg 0;
    width: 11rem;
  }
  .types {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: $layout-spacing-sm;
    padding: 0 $layout-spacing;
  }
}
#modal-expense-print {
  .expense-list {
    display: flex;
    flex-direction: column;
    height: 20rem;
    margin-top: $layout-spacing;
    overflow-y: auto;
    .list-header {
      align-items: center;
      border-bottom: $border-width solid $border-color;
      display: flex;
      padding-bottom: $layout-spacing;
      .list-title {
        flex: 1;
        font-weight: bold;
      }
    }
    .expense-scroll-list-wrapper {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      flex-grow: 1;
      margin-bottom: $layout-spacing-lg * 2;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding: $layout-spacing $layout-spacing-lg;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
      .table th, .table td {
        padding: 0;
      }
      .table td {
        border-bottom: none;
      }
    }
  }
}
</style>
